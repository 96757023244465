import React from 'react'
import Layout from "@layout";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import SEO from "@components/seo";
import { normalizedData } from "@utils/functions";
import ProjectInfo from "../components/projectInfo";
import Refund from '../components/privacy/Refund';

const RefundPolicy = ({ data }) => {
    const globalContent = normalizedData(data?.allGeneral?.nodes || []);
    return (
        <Layout
            data={{
                ...globalContent["menu"],
                ...globalContent["footer"],
            }}
        >
            <SEO
                title="Refund Policy at"
                pathname="/refund-policy"
                description="Woorg : The fastest growing performance marketing and advertising agency with clients around 5 continents in multiple industries. Connect now to boost your business."
            />

            <Refund />
            <ProjectInfo />
        </Layout>
    )
}

RefundPolicy.propTypes = {
    location: PropTypes.object,
    pageContext: PropTypes.object,
    data: PropTypes.shape({
        allGeneral: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
    }),
};

export const query = graphql`
    query RefundPageQuery {
        allGeneral {
            nodes {
                section
                id
                menu {
                    ...Menu
                }
                footer {
                    socials {
                        color
                        link
                        icon
                    }
                    ...Footer
                }
            }
        }
    }
`;

export default RefundPolicy
