import React from "react";
import { Container } from "react-bootstrap";
import { Title, SubTitle, Description, MainSection, Wrapper, Span } from "./style";

const Refund = () => {
    return (
        <MainSection>
            <Container className="w-75">
                <Title>Refund Policy – Woorg
                </Title>
                <Description>
                    At Woorg, we are committed to delivering high-quality services in software development, ERP solutions, digital marketing, video production, and more. Customer satisfaction is our top priority, and we strive to meet your expectations. However, we understand that circumstances may arise where a refund is necessary. Below is our refund policy, which outlines the terms and conditions for refunds across our services:
                </Description>
                <SubTitle>1. General Refund Conditions
                </SubTitle>
                <Description>
                    <Wrapper>
                        <li >Refunds are applicable only if the client provides valid reasons and sufficient evidence for dissatisfaction with the services rendered.</li>
                        <li >Refund requests must be submitted within 15 days of the project milestone or service delivery.</li>
                        <li >Refunds will not be issued for delays or interruptions caused by the client, such as unresponsiveness, incomplete project scope, or lack of required information.</li>
                    </Wrapper>
                </Description>

                <SubTitle>2. Software Development & ERP Solutions
                </SubTitle>
                <Description>
                    <Wrapper>
                        <li ><Span>Full Refund:</Span>  If the project is cancelled before any work has been initiated.</li>
                        <li ><Span>Partial Refund:</Span>If the project is cancelled after a portion of the work has been completed, a refund will be issued based on the work completed up to the point of cancellation.</li>
                        <li ><Span>No Refund:</Span> Once the project is delivered and accepted by the client.</li>
                    </Wrapper>
                </Description>

                <SubTitle>3. Digital Marketing Services </SubTitle>
                <Description>
                    <Wrapper>
                        <li ><Span>Full Refund:</Span> If the client cancels the campaign before the setup or any advertising budget is spent.</li>
                        <li ><Span>Partial Refund:</Span>If the campaign is already running, a partial refund may be issued for the remaining unused portion of the budget. Service fees will not be refunded.</li>
                        <li ><Span>No Refund:</Span> Once a campaign has been fully executed and the results delivered, refunds will not be issued.</li>
                    </Wrapper>
                </Description>

                <SubTitle>4. Video Production
                </SubTitle>
                <Description>
                    <Wrapper>
                        <li ><Span>Full Refund:</Span> If cancelled before pre-production (concept development and scripting) begins.</li>
                        <li ><Span>Partial Refund:</Span> For cancellations after pre-production but before post-production (editing) starts, a partial refund will be issued based on completed stages.</li>
                        <li ><Span>No Refund:</Span>  After final video delivery and client approval.
                        </li>
                    </Wrapper>
                </Description>

                <SubTitle>5. Custom Projects & Retainers</SubTitle>
                <Description>
                    <Wrapper>
                        <li ><Span>Full Refund:</Span> If no work has been started on custom projects or ongoing retainer services.
                        </li>
                        <li ><Span>Partial Refund:</Span>  For work in progress or partially delivered services, a prorated refund may be issued based on the completion level.</li>
                        <li ><Span>No Refund:</Span>  After full service or project completion and approval by the client.
                        </li>
                    </Wrapper>
                </Description>

                <SubTitle>6. Non-Refundable Situations</SubTitle>
                <Description>
                    <Wrapper>
                        <li > Customised services or bespoke solutions where work has been fully executed based on the client’s requirements.
                        </li>
                        <li > Failure to provide required project inputs or delays caused by the client.
                        </li>
                        <li > Disputes arising from third-party tool or platform usage not managed by [Your IT Firm Name].
                        </li>

                    </Wrapper>
                </Description>

                <SubTitle>7. Refund Process</SubTitle>
                <Description>
                    <Wrapper>
                        <li > To initiate a refund request, please contact us at info@woorg.com with your project details and reason for the refund.
                        </li>
                        <li > All refund requests will be reviewed and responded to within 10 business days.
                        </li>
                        <li > Approved refunds will be processed within 15 business days after confirmation, via the original payment method.
                        </li>

                    </Wrapper>
                </Description>
                <SubTitle>8. Modifications to the Policy</SubTitle>
                <Description>
                    Woorg reserves the right to modify this refund policy at any time. Clients will be notified of any significant changes in the policy.
                </Description>
                <SubTitle>9. Contact Us</SubTitle>
                <Description>
                    If you have any questions about our refund policy or need further clarification, please reach out to our support team at info@woorg.com.
                </Description>
            </Container>
        </MainSection>
    );
};

export default Refund;
